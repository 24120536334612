import React from "react";
import { useLocation } from "react-router-dom";
import {HistoryItemCard} from '../../components/HistoryItemCard/HistoryItemCard'

export const OrderDetails = () => {
  const location = useLocation();
  const { order } = location.state;
  return (
    <div className="grid-cols-2 flex mt-12 md:flex-row flex-col text-black">
      <div className="md:w-[65%] w-[100%] md:mx-10 md:px-10 px-2 py-5 border-2 rounded-3xl order-2 md:order-1">
        <p className="font-bold text-[24px]">Order Details</p>
        <div className="flex flex-col md:mx-16 mt-5 gap-y-5">
          {order.products?.length > 0 ? (
            order.products.map((item, index) => (
              <div key={index}>
                <HistoryItemCard item={item} />
              </div>
            ))
          ) : (
            <div className="flex items-center justify-center">
              Loading...
            </div>
          )}
        </div>
      </div>
      <div className="md:w-[35%] w-[100%] md:h-[100vh] mb-10 md:mb-0 h-[65vh] md:mx-10 px-10 py-5 border-2 rounded-3xl order-1 md:order-2">
        <div className="">
          <p className="font-bold text-[24px]">Receipt</p>
          <div className="flex justify-between mt-8">
            <p>No. of Items</p>
            <p>{order.totalItems}</p>
          </div>
          <div className="flex justify-between mt-2">
            <p>Subtotal</p>
            <p>£{order.totalBill}</p>
          </div>
          <div className="flex justify-between mt-2">
            <p>Discount</p>
            <p>£{order.totalBill - order.discountedBill}</p>
          </div>
          <div className="h-[1px] w-[100%] bg-gray-500 my-4"></div>
          <div className="flex justify-between mb-10">
            <p>Total</p>
            <p>£{order.discountedBill}</p>
          </div>
        </div>
        <div className="">
          <p className="font-bold text-[24px]">Customer Details</p>
          <div className="flex justify-between mt-8">
            <p className="font-bold">Name:</p>
            <p>{order.firstName+" "+order.lastName}</p>
          </div>
          <div className="flex justify-between mt-2">
            <p>{order.email}</p>
          </div>
          <div className="flex justify-between mt-2">
            <p className="font-bold">Address:</p>
          </div>
          <div className="flex justify-between mb-10">
            <p>{order.apartmentSuite +" "+ order.address+" "+order.city+" "+order.country + " " + order.postalCode}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
