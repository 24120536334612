import React, { useState } from "react";
import { Textbox } from "../../components/Textbox/Textbox";
import { useNavigate } from "react-router-dom";
import { LoginApi } from "../../api/internal/Auth";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/userSlice";
import { getItemsofCart } from "../../api/internal/CartApi";
import {
  addItem,
  resetCart,
  updateCartTotals,
  updateCartId,
} from "../../store/cartSlice";

export const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const CreateAccountBtnClick = () => {
    navigate("/signup");
  };
  const LoginBtnClicked = async () => {
    if (email === "" && password === "") {
      setError("Please enter both email and password.");
    } else {
      try {
        const data = await LoginApi({ email, password });

        if (data.message) {
          setError(data.message);
        } else {
          const user = {
            _id: data.User._id,
            email: data.User.email,
            firstname: data.User.firstname,
            lastname: data.User.lastname,
            auth: true,
          };
          dispatch(setUser(user));
          dispatch(resetCart());
          try {
            let data = await getItemsofCart(user._id);
            const dataa = {
              cartId: data._id,
            };
            dispatch(updateCartId(dataa));
            let productsArray = data.products;
            productsArray.map((pro) => {
              let itemtoAdd = {
                _id: pro.productId._id,
                imageLink: pro.productId.imageLink,
                title: pro.productId.title,
                description: pro.productId.description,
                category: pro.productId.category,
                subcategoryslug: pro.productId.subcategoryslug,
                price: pro.productId.price,
                discountedPrice: pro.productId.discountedPrice,
                reviews: pro.productId.reviews,
                rating: pro.productId.rating,
                size: pro.size,
                qty: pro.qty,
              };
              dispatch(addItem(itemtoAdd));
            });

            dispatch(
              updateCartTotals({
                totalItems: data.totalItems,
                totalBill: data.totalBill,
                discountedBill: data.discountedBill,
              })
            );
          } catch {
            dispatch(resetCart());
          }
          navigate("/");
        }
      } catch (error) {
        alert("Internet Error");
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center mt-16">
      <p className="text-[24px] font-bold">Login</p>
      <div className="w-[100%] flex justify-center items-center mb-4">
        <Textbox
          type="text"
          placeholder="Enter Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <p className="ml-[240px] md:text-[14px] text-[10px]">Forgot Password?</p>
      <Textbox
        type="password"
        placeholder="Enter Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <p className="text-red-500 text-[15px] mt-3 font-bold">{error}</p>
      <button className="btn mt-5" onClick={LoginBtnClicked}>
        Login
      </button>
      <p className="mt-5">New here?</p>
      <button className="btn" onClick={CreateAccountBtnClick}>
        Create Account
      </button>
    </div>
  );
};
