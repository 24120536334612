import { DEV_APIBaseUrl } from "../../config/config";

export const getReviewofProduct = async (productId) => {
  let apiLink = DEV_APIBaseUrl + "/review/get/" + productId;
  try {
    const response = await fetch(apiLink, {
      method: "GET",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error during Getting products", error);
    throw error;
  }
};
