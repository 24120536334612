import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    _id: "",
    email:"",
    firstname:"",
    lastname:"",
    auth:false

};


export const userSlice = createSlice({
    name:"user",
    initialState:initialState,
    reducers: {
        setUser: (state,action) => {
            const {_id,email, auth, firstname, lastname} = action.payload
            state._id = _id
            state.email = email
            state.auth = auth
            state.firstname = firstname
            state.lastname = lastname

        },
        resetUser: (state)=>{
            state._id = ""
            state.email = ""
            state.firstname = ""
            state.lastname = ""
            state.auth = false

        }
    }

})

export const {setUser, resetUser} = userSlice.actions;

export default userSlice.reducer;