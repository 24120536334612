import React, { useState, useEffect } from "react";
import { getCategories } from "../../api/internal/Categories";
import {
  UserIcon,
  MagnifyingGlassIcon,
  ShoppingCartIcon,
  ChevronDownIcon,
  Bars3Icon,
  DocumentTextIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthModal from "../../screens/AuthModal/AuthModal";
import { useNavigate } from "react-router-dom";

export const Navbar = () => {
  const [showModal, setShowModal] = useState(false);
  const [categories, setCategory] = useState(null);
  const [mobileNav, setMobileNav] = useState(false);
  const [menMenu, setMenMenu] = useState(false);
  const [womenMenu, setWomanMenu] = useState(false);
  const [girlsMenu, setGirlsMenu] = useState(false);
  const [boysMenu, setBoysMenu] = useState(false);
  const [booksMenu, setBooksMenu] = useState(false);
  const [perfumesMenu, setPerfumesMenu] = useState(false);
  const [weddingSpecials, setWeddingSpecials] = useState(false);
  const [accessoriesSubMenu, setAccessoriesSubMenu] = useState(false);
  const cartItemsCount = useSelector((state) => state.cart.totalItems);
  const cartState = useSelector((state) => state.cart);
  const auth = useSelector((state) => state.user.auth);
  const navigate = useNavigate();
  let NavbarItems = [
    "Men",
    "Women",
    "Girls",
    "Boys",
    "Books",
    "Perfumes",
    "Wedding Specials",
    "Accessories",
  ];

  useEffect(() => {
    const LoadSubCategories = async () => {
      setCategory(await getCategories());
    };
    LoadSubCategories();
  }, []);

  const handleAddToCartClick = () => {
    navigate("/cart");
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {/* Desktop Navbar */}
      <nav className="hidden md:flex ">
        <div className="flex w-[300px] justify-between">
          {/* Hijabs */}
          <div className="relative group">
            <div className="flex items-center space-x-1 cursor-pointer">
              <p>Men</p>
              <ChevronDownIcon className="h-4 w-4 text-gray-500" />
            </div>
            <div className="absolute hidden group-hover:block bg-white shadow-lg border mt-1 w-[200px] z-10">
              <NavLink
                to={`/men`}
                className="block px-4 py-2 text-black hover:bg-gray-100"
              >
                Browse All
              </NavLink>
              {categories
                ? categories[0].subCategories.map((item, index) => (
                    <NavLink
                      key={index}
                      to={`/men/${item.slug}`}
                      className="block px-4 py-2 text-black hover:bg-gray-100"
                    >
                      {item.name}
                    </NavLink>
                  ))
                : null}
            </div>
          </div>

          {/* Abayas */}
          <div className="relative group">
            <div className="flex items-center space-x-1 cursor-pointer">
              <p>Women</p>
              <ChevronDownIcon className="h-4 w-4 text-gray-500" />
            </div>
            <div className="absolute hidden group-hover:block bg-white shadow-lg border mt-1 w-[200px] z-10">
              <NavLink
                to={`/women`}
                className="block px-4 py-2 text-black hover:bg-gray-100"
              >
                Browse All
              </NavLink>

              {categories
                ? categories[1].subCategories.map((item, index) => (
                    <NavLink
                      key={index}
                      to={`/women/${item.slug}`}
                      className="block px-4 py-2 text-black hover:bg-gray-100"
                    >
                      {item.name}
                    </NavLink>
                  ))
                : null}
            </div>
          </div>

          {/* Accessories */}
          {/* More */}
          <div className="relative group">
            {/* Main "More" dropdown trigger */}
            <div className="flex items-center space-x-1 cursor-pointer">
              <p>More</p>
              <ChevronDownIcon className="h-4 w-4 text-gray-500" />
            </div>

            {/* Main dropdown content */}
            <div className="absolute hidden group-hover:block bg-white shadow-lg border mt-1 w-[200px] z-10">
              {categories?.slice(2, 8).map((category, catIndex) => (
                <div key={catIndex} className="group/item relative">
                  {/* Category item */}
                  <div className="px-4 py-2 flex justify-between items-center cursor-pointer hover:bg-gray-100">
                    <p className="text-black">{NavbarItems[catIndex + 2]}</p>
                    <ChevronRightIcon className="h-4 w-4 text-gray-500" />
                  </div>

                  {/* Subcategories dropdown */}
                  <div className="absolute left-full top-0 hidden group-hover/item:block bg-white shadow-lg border mt-0 w-[200px] z-20">
                    <NavLink
                      to={`/${NavbarItems[catIndex + 2]
                        ?.toLowerCase()
                        .replace(/\s+/g, "")}`}
                      className="block px-4 py-2 text-black hover:bg-gray-100"
                    >
                      Browse All
                    </NavLink>
                    {category.subCategories.map((item, subIndex) => (
                      <NavLink
                        key={subIndex}
                        to={`/${NavbarItems[catIndex + 2]
                          ?.toLowerCase()
                          .replace(/\s+/g, "")}/${item.slug}`}
                        className="block px-4 py-2 text-black hover:bg-gray-100"
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex w-[650px] justify-center">
          <NavLink
            to={"/"}
            style={{
              fontFamily: "'Emily's Candy', 'Pacifico', cursive",
              fontWeight: "bold",
              fontSize: 20,
            }}
          >
            The Unique Sunnah
          </NavLink>
        </div>

        <div className="flex space-x-7 ml-[145px]">
          <NavLink to={"/search"}>
            <MagnifyingGlassIcon className="h-6 w-6" />
          </NavLink>
          <NavLink to={"/Account"}>
            <UserIcon className="h-6 w-6" />
          </NavLink>
          <NavLink to={"/get-order-details"}>
            <DocumentTextIcon className="h-6 w-6" />
          </NavLink>
          <div className="relative">
            <div onClick={handleAddToCartClick} className="cursor-pointer">
              <ShoppingCartIcon className="h-6 w-6 text-black" />
              {cartItemsCount > 0 && (
                <span className="absolute -top-2 -right-2 bg-black text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                  {cartItemsCount}
                </span>
              )}
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navbar */}
      <div className="md:hidden">
        <nav className="flex items-center py-4 justify-between">
          <div className="flex gap-x-3 items-center">
            <div onClick={() => setMobileNav(!mobileNav)}>
              <Bars3Icon className="h-5 w-5 cursor-pointer" />
            </div>
            <div>
              <NavLink
                to={"/"}
                style={{
                  fontFamily: "'Emily's Candy', 'Pacifico', cursive",
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                The Unique Sunnah
              </NavLink>
            </div>
          </div>
          <div className="flex items-center gap-x-5">
            <NavLink to={"/search"}>
              <MagnifyingGlassIcon className="h-6 w-6" />
            </NavLink>
            <NavLink to={"/cart"} className={"relative"}>
              <ShoppingCartIcon className="h-6 w-6" />
              {cartItemsCount > 0 && (
                <span className="absolute -top-2 -right-2 bg-black text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                  {cartItemsCount}
                </span>
              )}
            </NavLink>
          </div>
        </nav>

        {/* Mobile Navigation Menu */}
        {mobileNav && (
          <div className="flex flex-col w-[200px] space-y-4 bg-white p-4 absolute z-10 left-0">
            {/* Men Menu */}
            <div>
              <div
                onClick={() => setMenMenu(!menMenu)}
                className="flex items-center justify-between hover:underline cursor-pointer"
              >
                <span>Men</span>
                <ChevronDownIcon className="h-4 w-4 text-gray-500" />
              </div>
              {menMenu && (
                <div className="ml-4 mt-2">
                  <NavLink
                    to={`/men`}
                    className="block text-black hover:bg-gray-100 py-1"
                  >
                    Browse All
                  </NavLink>
                  {categories
                    ? categories[0].subCategories.map((item, index) => (
                        <NavLink
                          key={index}
                          to={`/men/${item.slug}`}
                          className="block text-black hover:bg-gray-100 py-1"
                        >
                          {item.name}
                        </NavLink>
                      ))
                    : null}
                </div>
              )}
            </div>

            {/* Women Menu */}
            <div>
              <div
                onClick={() => setWomanMenu(!womenMenu)}
                className="flex items-center justify-between hover:underline cursor-pointer"
              >
                <span>Women</span>
                <ChevronDownIcon className="h-4 w-4 text-gray-500" />
              </div>
              {womenMenu && (
                <div className="ml-4 mt-2">
                  {womenMenu && (
                    <div className="ml-4 mt-2">
                      <NavLink
                        to={`/women`}
                        className="block text-black hover:bg-gray-100 py-1"
                      >
                        Browse All
                      </NavLink>
                      {categories
                        ? categories[1].subCategories.map((item, index) => (
                            <NavLink
                              key={index}
                              to={`/women/${item.slug}`}
                              className="block text-black hover:bg-gray-100 py-1"
                            >
                              {item.name}
                            </NavLink>
                          ))
                        : null}
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Girls Menu */}
            <div>
              <div
                onClick={() => setGirlsMenu(!girlsMenu)}
                className="flex items-center justify-between hover:underline cursor-pointer"
              >
                <span>Girls</span>
                <ChevronDownIcon className="h-4 w-4 text-gray-500" />
              </div>
              {girlsMenu && (
                <div className="ml-4 mt-2">
                  {girlsMenu && (
                    <div className="ml-4 mt-2">
                      <NavLink
                        to={`/girls`}
                        className="block text-black hover:bg-gray-100 py-1"
                      >
                        Browse All
                      </NavLink>
                      {categories
                        ? categories[2].subCategories.map((item, index) => (
                            <NavLink
                              key={index}
                              to={`/girls/${item.slug}`}
                              className="block text-black hover:bg-gray-100 py-1"
                            >
                              {item.name}
                            </NavLink>
                          ))
                        : null}
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Boys Menu */}
            <div>
              <div
                onClick={() => setBoysMenu(!boysMenu)}
                className="flex items-center justify-between hover:underline cursor-pointer"
              >
                <span>Boys</span>
                <ChevronDownIcon className="h-4 w-4 text-gray-500" />
              </div>
              {boysMenu && (
                <div className="ml-4 mt-2">
                  {boysMenu && (
                    <div className="ml-4 mt-2">
                      <NavLink
                        to={`/boys`}
                        className="block text-black hover:bg-gray-100 py-1"
                      >
                        Browse All
                      </NavLink>
                      {categories
                        ? categories[3].subCategories.map((item, index) => (
                            <NavLink
                              key={index}
                              to={`/boys/${item.slug}`}
                              className="block text-black hover:bg-gray-100 py-1"
                            >
                              {item.name}
                            </NavLink>
                          ))
                        : null}
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Books Menu */}
            <div>
              <div
                onClick={() => setBooksMenu(!booksMenu)}
                className="flex items-center justify-between hover:underline cursor-pointer"
              >
                <span>Books</span>
                <ChevronDownIcon className="h-4 w-4 text-gray-500" />
              </div>
              {booksMenu && (
                <div className="ml-4 mt-2">
                  {booksMenu && (
                    <div className="ml-4 mt-2">
                      <NavLink
                    to={`/books`}
                    className="block text-black hover:bg-gray-100 py-1"
                  >
                    Browse All
                  </NavLink>
                      {categories
                        ? categories[4].subCategories.map((item, index) => (
                            <NavLink
                              key={index}
                              to={`/books/${item.slug}`}
                              className="block text-black hover:bg-gray-100 py-1"
                            >
                              {item.name}
                            </NavLink>
                          ))
                        : null}
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Perfumes Menu */}
            <div>
              <div
                onClick={() => setPerfumesMenu(!perfumesMenu)}
                className="flex items-center justify-between hover:underline cursor-pointer"
              >
                <span>Perfumes</span>
                <ChevronDownIcon className="h-4 w-4 text-gray-500" />
              </div>
              {perfumesMenu && (
                <div className="ml-4 mt-2">
                  <NavLink
                    to={`/perfumes`}
                    className="block text-black hover:bg-gray-100 py-1"
                  >
                    Browse All
                  </NavLink>
                  {perfumesMenu && (
                    <div className="ml-4 mt-2">
                      {categories
                        ? categories[5].subCategories.map((item, index) => (
                            <NavLink
                              key={index}
                              to={`/perfumes/${item.slug}`}
                              className="block text-black hover:bg-gray-100 py-1"
                            >
                              {item.name}
                            </NavLink>
                          ))
                        : null}
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Wedding Menu */}
            <div>
              <div
                onClick={() => setWeddingSpecials(!weddingSpecials)}
                className="flex items-center justify-between hover:underline cursor-pointer"
              >
                <span>Wedding Specials</span>
                <ChevronDownIcon className="h-4 w-4 text-gray-500" />
              </div>
              {weddingSpecials && (
                <div className="ml-4 mt-2">
                  {weddingSpecials && (
                    <div className="ml-4 mt-2">
                      <NavLink
                    to={`/weddingspecials`}
                    className="block text-black hover:bg-gray-100 py-1"
                  >
                    Browse All
                  </NavLink>
                      {categories
                        ? categories[6].subCategories.map((item, index) => (
                            <NavLink
                              key={index}
                              to={`/weddingspecials/${item.slug}`}
                              className="block text-black hover:bg-gray-100 py-1"
                            >
                              {item.name}
                            </NavLink>
                          ))
                        : null}
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Accessories Menu */}
            <div>
              <div
                onClick={() => setAccessoriesSubMenu(!accessoriesSubMenu)}
                className="flex items-center justify-between hover:underline cursor-pointer"
              >
                <span>Accessories</span>
                <ChevronDownIcon className="h-4 w-4 text-gray-500" />
              </div>
              {accessoriesSubMenu && (
                <div className="ml-4 mt-2">
                  {accessoriesSubMenu && (
                    <div className="ml-4 mt-2">
                      <NavLink
                    to={`/accessories`}
                    className="block text-black hover:bg-gray-100 py-1"
                  >
                    Browse All
                  </NavLink>
                      {categories
                        ? categories[7].subCategories.map((item, index) => (
                            <NavLink
                              key={index}
                              to={`/accessories/${item.slug}`}
                              className="block text-black hover:bg-gray-100 py-1"
                            >
                              {item.name}
                            </NavLink>
                          ))
                        : null}
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Icons */}
            <div className="flex justify-around mt-6">
              <NavLink to={"/Account"}>
                <UserIcon className="h-6 w-6" />
              </NavLink>
              <NavLink to={"/get-order-details"}>
                <DocumentTextIcon className="h-6 w-6" />
              </NavLink>
            </div>
          </div>
        )}
      </div>

      <div className="w-[100%] h-[1px] bg-black my-1"></div>
      <AuthModal isOpen={showModal} onClose={handleCloseModal} />
    </>
  );
};
