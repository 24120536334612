import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Textbox } from "../../components/Textbox/Textbox";
import { SignUpApi } from "../../api/internal/Auth";

export const SignUp = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const CreateAccountBtnClick = async () => {
    if (
      firstName === "" &&
      lastName === "" &&
      email === "" &&
      password === ""
    ) {
      setError("Please enter data in all fields.")
    } else {
      const data = await SignUpApi({
        firstname: firstName,
        lastname: lastName,
        email: email,
        password: password,
      });
      console.log(data);
      if (data.message) {
        setError(data.message);
      } else {
        navigate("/login");
      }
    }
  };

  const GotoLoginBtnClick = () => {
    navigate("/account");
  };

  return (
    <div className="flex flex-col items-center justify-center mt-16">
      <p className="text-[24px] font-bold">Create Your Account</p>
      <div className="flex flex-col w-[100%] md:h-[50vh] h-[38vh] items-center justify-center">
        <Textbox
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Textbox
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <Textbox
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Textbox
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <p className="text-red-500 text-[15px] mt-3 font-bold">{error}</p>
      </div>
      <button className="btn" onClick={CreateAccountBtnClick}>
        Create Account
      </button>
      <button className="btn mt-6" onClick={GotoLoginBtnClick}>
        Go to Login
      </button>
    </div>
  );
};
