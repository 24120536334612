import React, { useEffect, useState } from "react";
import { GetOrders } from "../../api/internal/OrderApi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const OrderHistoryScreen = () => {
  const userId = useSelector((state) => state.user._id);
  const navigate = useNavigate()
  const [orders, setOrders] = useState(null)

  const getOrdersFromApi = async (id) => {
      setOrders(await GetOrders(id));
  };

  useEffect(() => {
    getOrdersFromApi(userId);
  }, []);

  return (
    <div className="h-[100vh] w-[90%] mx-auto">
      <div className="mt-5">
        <p className="bold text-[22px] font-bold">Your Orders</p>

        <div className="mt-5 mb-0 w-[100%] flex justify-between px-5 py-2 rounded-lg bg-black">
          <p className="text-white text-[8px] md:text-lg">Customer Name</p>
          <p className="text-white text-[8px] md:text-lg">Paid Amount</p>
          <p className="text-white text-[8px] md:text-lg">Payment Method</p>
          <p className="text-white text-[8px] md:text-lg">Order Status</p>
          <p className="text-white text-[8px] md:text-lg">Details</p>
        </div>
        <div>
          {orders?.length > 0 ? (orders.map((item, index) => {
            return (
              <div key={index} className=" my-3 w-[100%] gap-y-2 border-2 flex items-center justify-between px-5 py-4 rounded-lg">
                <p className="text-[8px] md:text-lg">{item.userId.firstname}</p>
                <p className="text-[8px] md:text-lg">£{item.discountedBill}</p>
                <p className="text-[8px] md:text-lg">{item.paymentMethod}</p>
                <p className="text-[8px] md:text-lg">{item.OrderStatus}</p>
                <button onClick={()=>navigate('/orderdetails', { state: { order: item } })} className="bg-black text-white  text-[5px] md:text-lg rounded px-4 py-1 cursor-pointer">
                  Details
                </button>
              </div>
            );
          })): <div className="h-[50vh] flex items-center justify-center"> No Orders yet </div>}
        </div>
      </div>
    </div>
  );
};
