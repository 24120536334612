import React from 'react'
import notfound from '../../assets/notfound.png'
import { useNavigate } from 'react-router-dom'

export const NotFound = () => {
    const navigate = useNavigate()
  return (
    <div className='h-[100vh] w-[100%] flex flex-col items-center justify-center'>
        <img src={notfound} height={300} width={300} alt="" />
        <p className='font-bold text-[26px] my-5'>Page Not Found</p>
        <button className='btn' onClick={()=>navigate("/")}>Go to Home Page</button>
    </div>
  )
}
