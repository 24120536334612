import React from "react";
import { StarIcon } from "@heroicons/react/24/solid"; // for filled stars
import { StarIcon as OutlineStarIcon } from "@heroicons/react/24/outline"; // for outlined stars

export const Reviews = (props) => {
    let reviews = props.reviews
//   const reviews = [
//     {
//       name: "Waseem",
//       rating: 4,
//       review:
//         "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores amet accusamus laudantium error debitis illo! Neque sit facilis nemo sint harum repellat, ad officia maxime vero at pariatur esse magnam!",
//     },
//     {
//       name: "Abdullah",
//       rating: 5,
//       review:
//         "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores amet accusamus laudantium error debitis illo! Neque sit facilis nemo sint harum repellat, ad officia maxime vero at pariatur esse magnam!",
//     },
//     {
//         name: "Abdullah",
//         rating: 5,
//         review:
//           "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores amet accusamus laudantium error debitis illo! Neque sit facilis nemo sint harum repellat, ad officia maxime vero at pariatur esse magnam!",
//       },
//   ];

  return (
    <div className="mt-16 mb-5 bg-white border-2 z-50 p-4 rounded-xl">
      <p className="text-[20px] font-bold">Reviews:</p>
      {reviews?.length > 0 ? (reviews.map((item, index) => (
        <div key={index}>
          <div className="h-[1px] w-[100%] bg-gray-200 mx-auto my-3"></div>
          <div className="flex justify-between mt-5">
            <p className="text-[16px] font-bold">{item.userName}</p>
            {/* Star rating section */}
            <div className="flex items-center">
              {Array.from({ length: 5 }).map((_, i) =>
                i < item.rating ? (
                  <StarIcon key={i} className="h-5 w-5 text-yellow-400" />
                ) : (
                  <OutlineStarIcon key={i} className="h-5 w-5 text-yellow-400" />
                )
              )}
            </div>
          </div>
          <div className="mt-2">
            <p>{item.review}</p>
          </div>
        </div>
      ))): <div className="flex items-center justify-center">No Reviews</div>}
    </div>
  );
};
