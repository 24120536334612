import React from "react";
import { CartItemCard } from "../../components/CartItemCard/CartItemCard";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const Cart = () => {
  const navigate = useNavigate()
  let NumberOfItems = useSelector((state) => state.cart.totalItems)
  let totalBill = useSelector((state) => state.cart.totalBill)
  let discountedPrice = useSelector((state) => state.cart.discountedBill)
  let Items = useSelector((state) => state.cart.items);

  const CheckoutBtnClick = () => {
    if(Items && Items.length > 0)
    {
        navigate("/checkout")
    }
    else{
      alert("Add Items to cart first")
    }
  }

  return (
    <div className="grid-cols-2 flex mt-12 md:flex-row flex-col">
      <div className="md:w-[65%] w-[100%] md:mx-10 md:px-10 px-2 py-5 border-2 rounded-3xl order-2 md:order-1">
        <p className="font-bold text-[24px]">Cart Items</p>
        <div className="flex flex-col md:mx-16 mt-5 gap-y-5">
        {Items?.length > 0 ? (
            Items.map((item, index) => (
              <div key={index}>
                <CartItemCard item={item} />
              </div>
            ))
          ) : (
            <div className="flex items-center justify-center">No Items in Cart</div>
          )}
        </div>
      </div>
      <div className="md:w-[35%] w-[100%] md:h-[75vh] h-[65vh] md:mx-10 px-10 py-5 border-2 rounded-3xl order-1 md:order-2">
        <div className="">
          <p className="font-bold text-[24px]">Items Summary</p>
          <div className="flex justify-between mt-8">
            <p>No. of Items</p>
            <p>{NumberOfItems}</p>
          </div>
          <div className="flex justify-between mt-2">
            <p>Subtotal</p>
            <p>£{totalBill}</p>
          </div>
          <div className="flex justify-between mt-2">
            <p>Discount</p>
            <p>£{totalBill - discountedPrice}</p>
          </div>
          <div className="h-[1px] w-[100%] bg-gray-500 my-4"></div>
          <div className="flex justify-between mb-10">
            <p>Total</p>
            <p>£{discountedPrice}</p>
          </div>
          <div className="flex flex-col gap-y-3">
            <button className="lightBtn" onClick={()=>navigate("/")}>Shop More</button>
            <button className="btnbig" onClick={CheckoutBtnClick}>Checkout</button>
          </div>
        </div>
      </div>
    </div>
  );
};
