import React from 'react';
import { useNavigate } from 'react-router-dom';

const AuthModal = ({ isOpen, onClose }) => {
   const navigate = useNavigate()  
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white text-black w-[90%] sm:w-[400px] p-8 rounded-lg shadow-lg border border-gray-200 relative min-h-[380px]">
        {/* Close Button */}
        <button
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 text-3xl"
          onClick={onClose}
        >
          &times;
        </button>

        {/* Catchy Title */}
        <h2 className="text-3xl font-extrabold mb-4 text-center text-black">
          Ready to Shop?
        </h2>

        {/* Subtitle */}
        <p className="text-center text-gray-600 mb-8">
          Login or Sign Up to access your cart and start shopping!
        </p>

        {/* Buttons */}
        <div className="flex justify-center gap-4">
          <button
            className="bg-black text-white hover:bg-gray-800 font-semibold py-2 px-5 rounded w-full sm:w-[45%] transition-all"
            onClick={() => {
              navigate("/login");
              onClose();
            }}
          >
            Log In
          </button>

          <button
            className="bg-transparent border border-black text-black hover:bg-gray-100 font-semibold py-2 px-5 rounded w-full sm:w-[45%] transition-all"
            onClick={() => {
              navigate("/signup")
              onClose();
            }}
          >
            Sign Up
          </button>
        </div>

        {/* Footer Text */}
        <p className="mt-6 text-center text-gray-500 text-sm">
          Create an account for exclusive offers and a seamless shopping experience!
        </p>
      </div>
    </div>
  );
};

export default AuthModal;
