import { DEV_APIBaseUrl } from "../../config/config";



export const AddItemToCartAPI = async (data) => {
    try {
        const response = await fetch(DEV_APIBaseUrl + '/cart/addProduct', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        const result = await response.json();
        return result; 

    } catch (error) {
        console.error('Error during Adding item to cart:', error);
        throw error; 
    }

}

export const getItemsofCart = async (userId) => {
    
    try {
        const response = await fetch(DEV_APIBaseUrl + '/cart/getCartItems/' + userId , {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const result = await response.json();
        return result.data;
        
    } catch (error) {
        console.error('Error during Adding item to cart:', error);
        throw error; 
    }
}

 
export const RemoveItemsFromCartAPI = async (data) => {
    try {
        const response = await fetch(DEV_APIBaseUrl + '/cart/deletecartitem' , {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            
        });

        const result = await response.json();
        return result;
        
    } catch (error) {
        console.error('Error during Deleting item from cart:', error);
        throw error; 
    }
}