import { DEV_APIBaseUrl } from "../../config/config";

export const getProductByCategory = async (catergoryname, limit) => {
  let apiLink =
    DEV_APIBaseUrl + "/product/get/" + catergoryname + "?" + "limit=" + limit;
  console.log(apiLink);
  try {
    const response = await fetch(apiLink, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error during Getting products", error);
    throw error;
  }
};

export const getProductBySubCategory = async (
  catergoryname,
  subCategoryName,
  limit
) => {
  let apiLink =
    DEV_APIBaseUrl +
    "/product/get/" +
    catergoryname +
    "/" +
    subCategoryName +
    "?limit=" +
    limit;
  console.log(apiLink);
  try {
    const response = await fetch(apiLink, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error during Getting products", error);
    throw error;
  }
};

export const getAllProductsAPI = async () => {
  let apiLink = DEV_APIBaseUrl + "/product/get";
  try {
    const response = await fetch(apiLink, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error during Getting products", error);
    throw error;
  }
};
