import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    firstName: "",
    lastName:"",
    country: "",
    address: "",
    apartmentSuite: "",
    city: "",
    postalCode: "",
    phone: "",
    email:"",
};


export const userDataSlice = createSlice({
    name:"userData",
    initialState:initialState,
    reducers: {
        setUserData: (state,action) => {
            const {firstName,lastName,country,address, apartmentSuite, city, postalCode, phone, email} = action.payload
            state.lastName = lastName
            state.firstName = firstName
            state.country = country
            state.address = address
            state.apartmentSuite = apartmentSuite
            state.city = city
            state.postalCode = postalCode
            state.phone = phone
            state.email = email

        },
        resetUserData: (state)=>{
            state.firstName = ""
            state.lastName = ""
            state.country = ""
            state.address = ""
            state.apartmentSuite = ""
            state.city = ""
            state.postalCode = ""
            state.phone = ""
            state.email = ""

        }
    }

})

export const {setUserData, resetUserData} = userDataSlice.actions;

export default userDataSlice.reducer;