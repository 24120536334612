import "./App.css";
import { Navbar } from "./components/Navbar/Navbar";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Home } from "./screens/Home/Home";
import { Login } from "./screens/Login/Login";
import { SignUp } from "./screens/SignUp/SignUp";
import { Footer } from "./components/Footer/Footer";
import { Cart } from "./screens/Cart/Cart";
import { ProductPage } from "./screens/ProductPage/ProductPage";
import { NotFound } from "./screens/NotFound/NotFound";
import Protected from "./components/Protected/Protected";
import { AccountScreen } from "./screens/AccountScreen/AccountScreen";
import { useSelector } from "react-redux";
import { SearchScreen } from "./screens/SearchScreen/SearchScreen";
import { CategoryScreen } from "./screens/CategoryScreen/CategoryScreen";
import { CheckoutScreen } from "./screens/CheckoutScreen/CheckoutScreen";
import { PaymentScreen } from "./screens/PaymentScreen/PaymentScreen";
import { OrderHistoryScreen } from "./screens/OrderHistoryScreen/OrderHistoryScreen";
import { OrderDetails } from "./screens/OrderDetails/OrderDetails";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { SearchOrderScreen } from "./screens/SearchOrderScreen/SearchOrderScreen";
import Checkout from "./screens/Stripe/Checkout";
import { PaymentSuccess } from "./screens/PaymentSuccess/PaymentSuccess";
import { GetOrderDetailsScreen } from "./screens/GetOrderDetailsScreen/GetOrderDetailsScreen";
// import GoogleTranslate from "./services/GoogleTranslate";
function App() {
  let isAuth = useSelector((state) => state.user.auth);
  return (
    <>
      <div>
        <BrowserRouter>
          <div className="px-5 my-5 max-w-[1280px] xl:mx-auto">
            <ScrollToTop />
            <Navbar />
            <Routes>
              <Route
                path="/"
                exact
                element={
                  <div>
                    <Home />
                  </div>
                }
              />

              <Route
                path="/login"
                exact
                element={
                  <div>
                    <Login />
                  </div>
                }
              />

              <Route
                path="/account"
                exact
                element={
                  <Protected isAuth={isAuth}>
                    <div>
                      <AccountScreen />
                    </div>
                  </Protected>
                }
              />

              <Route
                path="/:category/:subcategory"
                exact
                element={
                  <div>
                    <CategoryScreen />
                  </div>
                }
              />

              <Route
                path="/:category"
                exact
                element={
                  <div>
                    <CategoryScreen />
                  </div>
                }
              />

              <Route
                path="/signup"
                exact
                element={
                  <div>
                    <SignUp />
                  </div>
                }
              />

              <Route
                path="/cart"
                exact
                element={
                  <div>
                    <Cart />
                  </div>
                }
              />

              <Route
                path="/checkout"
                exact
                element={
                  <div>
                    <CheckoutScreen />
                  </div>
                }
              />

              <Route
                path="/orderhistory"
                exact
                element={
                  <Protected isAuth={isAuth}>
                    <div>
                      <OrderHistoryScreen />
                    </div>
                  </Protected>
                }
              />

              <Route
                path="/orderdetails"
                exact
                element={
                    <div>
                      <OrderDetails />
                    </div>
                }
              />

              <Route
                path="/payment"
                exact
                element={
                  <div>
                    <PaymentScreen />
                  </div>
                }
              />

              <Route
                path="/product/:id"
                exact
                element={
                  <div>
                    <ProductPage />
                  </div>
                }
              />

              <Route
                path="/search"
                exact
                element={
                  <div>
                    <SearchScreen />
                  </div>
                }
              />

              <Route
                path="/search-order"
                exact
                element={
                  <div>
                    <SearchOrderScreen />
                  </div>
                }
              />

              <Route
                path="/newcheckout"
                exact
                element={
                  <div>
                    <Checkout />
                  </div>
                }
              />

              <Route
                path="/payment/success"
                exact
                element={
                  <div>
                    <PaymentSuccess />
                  </div>
                }
              />

              <Route
                path="/get-order-details"
                exact
                element={
                  <div>
                    <GetOrderDetailsScreen />
                  </div>
                }
              />

              <Route
                path="*"
                element={
                  <div>
                    <NotFound />
                  </div>
                }
              />
            </Routes>
          </div>
          <div className="max-w-[1280px] xl:mx-auto">
            <Footer />
          </div>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
