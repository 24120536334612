import { DEV_APIBaseUrl } from "../../config/config";

export const getCategories = async (data) => {
    try {
        const response = await fetch(DEV_APIBaseUrl + '/category/get', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        const result = await response.json();
        return result; 

    } catch (error) {
        console.error('Error during login:', error);
        throw error; 
    }
}


