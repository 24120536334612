import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { PlaceOrder } from "../../api/internal/OrderApi";
import { resetCart } from "../../store/cartSlice";
import { resetUserData } from "../../store/userDataSlice";
import { useEffect } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { XCircleIcon } from "@heroicons/react/24/solid"; // Heroicons v2


export const PaymentSuccess = () => {
  const [msg, setmsg] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.user.auth);
  let NumberOfItems = useSelector((state) => state.cart.totalItems);
  let totalBill = useSelector((state) => state.cart.totalBill);
  let discountedPrice = useSelector((state) => state.cart.discountedBill);
  let cardId = useSelector((state) => state.cart.cartId);
  let items = useSelector((state) => state.cart.items);
  let firstName = useSelector((state) => state.userData.firstName);
  let lastName = useSelector((state) => state.userData.lastName);
  let email = useSelector((state) => state.userData.email);
  let country = useSelector((state) => state.userData.country);
  let city = useSelector((state) => state.userData.city);
  let postalCode = useSelector((state) => state.userData.postalCode);
  let phone = useSelector((state) => state.userData.phone);
  let address = useSelector((state) => state.userData.address);
  let apartmentSuite = useSelector((state) => state.userData.apartmentSuite);

  useEffect(() => {
    ProceedToPaymentBtnClick();
  }, []);
  const ProceedToPaymentBtnClick = async () => {
    if (auth) {
      const data = {
        cartId: cardId,
        firstName: firstName,
        lastName: lastName,
        email: email,
        country: country,
        address: address,
        apartmentSuite: apartmentSuite,
        city: city,
        postalCode: postalCode,
        phone: phone,
        paymentMethod: "card",
        orderStatus: "placed",
      };
      const res = await PlaceOrder(data);
      if (res.msg === "Order Placed") {
        dispatch(resetCart());
        dispatch(resetUserData());
        setmsg(res.data._id);
      } else {
        setmsg("Order not Placed");
      }
    } else {
      const data = {
        products: items,
        totalItems: NumberOfItems,
        totalBill: totalBill,
        discountedBill: discountedPrice,
        firstName: firstName,
        lastName: lastName,
        email: email,
        country: country,
        address: address,
        apartmentSuite: apartmentSuite,
        city: city,
        postalCode: postalCode,
        phone: phone,
        paymentMethod: "card",
        orderStatus: "placed",
      };
      const res = await PlaceOrder(data);
      if (res.msg === "Order Placed") {
        dispatch(resetCart());
        dispatch(resetUserData());
        setmsg(res.data._id);
      } else {
        setmsg("Order not Placed");
      }
    }
  };
  return (
    <div className="w-[100%] h-[50vh] md:h-[90vh] flex items-center justify-center flex-col">
      {
        msg !== "Order not Placed" ? (
          <>
          <CheckCircleIcon className="h-24 w-24 text-green-500 mb-4" />
          <h1 className="font-bold text-[24px]">Order Placed</h1>
          <h1 className="font-bold">Order Id is : <span>{msg}</span></h1>
          </>
        ) : (
          <>
          <XCircleIcon className="h-24 w-24 text-red-500 mb-4" />
          <h1 className="font-bold text-[24px]">Error</h1>
          <h1 className="font-bold">There was some Error from our side</h1>
          </>
        )
      }
      {/* <CheckCircleIcon className="h-24 w-24 text-green-500 mb-4" />
      <h1 className="font-bold text-[24px]">Order Placed</h1>
      <h1 className="font-bold">Order Id is : <span>{msg}</span></h1> */}
    </div>
  );
};
