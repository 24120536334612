import React from 'react';

export const DSTextbox = (props) => {
  return (
    <input
      type={props.type}
      placeholder={props.placeholder}
      value={props.value}  // Assign the value prop
      onChange={props.onChange}  // Handle input changes with the onChange prop
      className='py-[12px] px-[27px] text-[16px] m-[5px] outline-none w-[80%]  md:w-[98%] border border-gray-500 rounded-lg focus:border-2 focus:border-black'
    />
  );
};
