import React, { useState, useEffect } from 'react';
import imag1 from '../../assets/caraousal-1.png';
import imag2 from '../../assets/caraousal-2.png';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

const images = [imag1, imag2];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(1);  // To handle the slide-in effect
  const [animating, setAnimating] = useState(false);  // To handle animation state

  const nextSlide = () => {
    if (!animating) {
      setAnimating(true);
      setNextIndex((prevIndex) => (prevIndex + 1) % images.length);
    }
  };

  const prevSlide = () => {
    if (!animating) {
      setAnimating(true);
      setNextIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    }
  };

  // Automatically change slides every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000); // 3 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  // Reset animation state and update currentIndex after slide transition
  useEffect(() => {
    if (animating) {
      const timeout = setTimeout(() => {
        setAnimating(false); // Reset animation state
        setCurrentIndex(nextIndex); // Update the index after animation ends
      }, 600); // Duration of the animation

      return () => clearTimeout(timeout); // Cleanup timeout
    }
  }, [animating, nextIndex]);

  return (
    <div className="flex justify-center my-8">
      <div className="relative h-[10vh] w-[100%] md:w-[85%] md:h-[45vh] overflow-hidden rounded-lg">
        <div
          className="flex w-full transition-transform duration-500 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          <img
            src={images[0]}
            alt={`Slide ${0 + 1}`}
            className="object-contain w-full h-full flex-shrink-0"
          />
          <img
            src={images[1]}
            alt={`Slide ${1 + 1}`}
            className="object-contain w-full h-full flex-shrink-0"
          />
        </div>
        <button
          onClick={prevSlide}
          className="absolute hidden md:block left-0 ml-5 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow"
        >
          <ChevronLeftIcon className="h-6 w-6 text-black" />
        </button>
        <button
          onClick={nextSlide}
          className="absolute hidden md:block mr-5 right-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow"
        >
          <ChevronRightIcon className="h-6 w-6 text-black" />
        </button>
      </div>
    </div>
  );
};

export default Carousel;
