import React, { useEffect, useState } from "react";
import imag from "../../assets/pic.jpg";
import { StarIcon } from "@heroicons/react/24/outline";
import { ProductCard } from "../../components/ProductCard/ProductCard";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getProductBySubCategory } from "../../api/internal/ProductApi";
import ClipLoader from "react-spinners/ClipLoader"; // Import spinner
import { useDispatch } from "react-redux";
import { addItem, updateCartId } from "../../store/cartSlice";
import { Reviews } from "../../components/Reviews/Reviews";
import { getReviewofProduct } from "../../api/internal/ReviewsApi";
import AuthModal from "../AuthModal/AuthModal";
import { useSelector } from "react-redux";
import { AddItemToCartAPI } from "../../api/internal/CartApi";

export const ProductPage = () => {
  const userId = useSelector((state) => state.user._id);
  const location = useLocation();
  const product = location.state?.product;
  let auth = useSelector((state) => state.user.auth);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [products, setProducts] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [mainImage, setMainImage] = useState(product.imageLink[0]);
  const [showModal, setShowModal] = useState(false);

  const handleImageClick = (clickedImage) => {
    const newImageLinks = product.imageLink.map((image) =>
      image === clickedImage ? mainImage : image
    );
    setMainImage(clickedImage);
    product.imageLink = newImageLinks;
  };

  const handleAddToCartClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setLoading(true);
    const GetProducts = async () => {
      try {
        const data = await getProductBySubCategory(
          product.category,
          product.subcategoryslug,
          5
        );
        setProducts(data);
      } catch (error) {
        console.error("Error fetching products", error);
      } finally {
        setLoading(false);
      }
    };
    const GetReviews = async () => {
      try {
        const data = await getReviewofProduct(product._id);
        setReviews(data);
      } catch (error) {
        console.error("Error fetching products", error);
      }
    };
    GetReviews();
    GetProducts();
  }, []);

  useEffect(() => {
    if (product) {
      setMainImage(product.imageLink[0]);
    }
  }, [product]);

  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [size, setSize] = useState(product.sizes[0]);
  const [qty, setQty] = useState(1);


  const AddItemToCart = async () => {
    let itemtoAdd = {
      _id: product._id,
      imageLink: product.imageLink,
      title: product.title,
      description: product.description,
      category: product.category,
      subcategoryslug: product.subcategoryslug,
      price: product.price,
      discountedPrice: product.discountedPrice,
      reviews: product.reviews,
      rating: product.rating,
      size: size,
      qty: qty,
    };
    dispatch(addItem(itemtoAdd));

    if(auth){
    let itemtoAdd2 = {
      userId: userId,
      productId: product._id,
      size: size,
      qty: qty,
    };
    const res = await AddItemToCartAPI(itemtoAdd2);

    const data = {
      cartId: res.cart._id,
    };
    dispatch(updateCartId(data));
  }
    setQty(1);
  };
  const BuyNowBtnClick = async () => {
    let itemtoAdd = {
      _id: product._id,
      imageLink: product.imageLink,
      title: product.title,
      description: product.description,
      category: product.category,
      subcategoryslug: product.subcategoryslug,
      price: product.price,
      discountedPrice: product.discountedPrice,
      reviews: product.reviews,
      rating: product.rating,
      size: size,
      qty: qty,
    };
    dispatch(addItem(itemtoAdd));

    if(auth){
    let itemtoAdd2 = {
      userId: userId,
      productId: product._id,
      size: size,
      qty: qty,
    };
    const res = await AddItemToCartAPI(itemtoAdd2);

    const data = {
      cartId: res.cart._id,
    };
    dispatch(updateCartId(data));
  }
    setQty(1);
    navigate("/checkout")
  };

  const navigate = useNavigate();

  return (
    <>
      <div className="md:h-auto h-[400vh] w-full mt-10">
        <div className="h-[70vh] w-full flex flex-col md:flex-row">
          {/* Main image */}
          <div className="w-full md:w-[30%] h-[430px] flex justify-center mb-5 md:mb-0">
            <img
              src={mainImage}
              alt=""
              className="h-full w-full object-cover"
            />
          </div>

          {/* Two images */}
          <div className="w-full md:w-[18%] flex items-end md:items-start md:flex-col md:h-full gap-x-4 md:gap-x-0 md:ml-10 md:gap-y-4 ">
            {/* Conditional rendering: if product.imageLink[1] is an empty string, render null */}
            {product.imageLink[1] && product.imageLink[2] !== "" && (
              <div
                className="w-[30%] h-[95px] opacity-85"
                onClick={() => handleImageClick(product.imageLink[1])}
              >
                <img
                  src={product.imageLink[1]}
                  alt=""
                  className="h-full w-full object-cover"
                />
              </div>
            )}

            {product.imageLink[2] && product.imageLink[2] !== "" && (
              <div
                className="w-[30%] h-[95px] opacity-85"
                onClick={() => handleImageClick(product.imageLink[2])}
              >
                <img
                  src={product.imageLink[2]}
                  alt=""
                  className="h-full w-full object-cover"
                />
              </div>
            )}
          </div>

          {/* Side data */}
          <div className="w-full md:w-[25%] h-full mt-14 md:mt-0">
            <div className="flex items-center mb-3">
              <StarIcon className="h-5 w-5 text-yellow-400 fill-current" />
              <p className="ml-1 font-bold text-lg">
                {product.rating}({product.reviews})
              </p>
            </div>
            <p className="text-[26px] font-bold mt-3 md:mt-12">
              {product.title}
            </p>
            <div className="flex items-center mt-3">
              <p className="text-[22px] line-through mr-2">£{product.price}</p>
              <p className="font-bold text-red-500 text-[22px]">
              £{product.discountedPrice}
              </p>
            </div>
            <div className="flex w-full mt-7  gap-x-4">
              {product.sizes.map((item) => (
                <div
                  key={item}
                  onClick={() => setSize(item)}
                  className={`cursor-pointer h-[50px] w-[50px] border border-black rounded-[25px] flex items-center justify-center ${
                    size === item
                      ? "bg-black text-white"
                      : "bg-white text-black"
                  }`}
                >
                  <p>{item}</p>
                </div>
              ))}
            </div>
            <div className="flex justify-between mt-12">
              <button
                onClick={AddItemToCart}
                className="btn"
              >
                Add to Cart
              </button>
              <div className="flex w-[40%] items-center justify-between">
                <div
                  onClick={() => (qty > 1 ? setQty(qty - 1) : null)}
                  className="cursor-pointer h-[40px] w-[40px] rounded-[20px] border flex items-center justify-center"
                >
                  <p className="text-[28px]">-</p>
                </div>
                <p>{qty}</p>
                <div
                  onClick={() => setQty(qty + 1)}
                  className="cursor-pointer h-[40px] w-[40px] rounded-[20px] border flex items-center justify-center"
                >
                  <p className="text-[28px]">+</p>
                </div>
              </div>
            </div>
            <button
              onClick={BuyNowBtnClick}
              className="h-[50px] w-[100%] rounded-lg border-2 border-black text-black mt-3 hover:bg-black hover:text-white transition-all"

            >
              Buy Now
            </button>

            {/* Description dropdown */}
            <div className="mt-10">
              <div className="mt-4 bg-white border-2 z-50 p-4 rounded-xl md:hidden">
                <p className="text-[18px] font-bold">Description:</p>
                <p className="text-[16px]">{product.description}</p>
              </div>
            </div>
            <div className="mt-[10vh] md:hidden">
              <Reviews reviews={reviews} />
            </div>

            <div className="md:mt-16 mt-[10vh] md:hidden">
              <div className="flex justify-between items-center">
                <p className="font-bold text-[19px] md:text-[21px] my-2">
                  You may also like
                </p>
              </div>
              <div>
                {loading ? (
                  <div className="flex justify-center items-center h-[200px]">
                    <ClipLoader color="#000" size={50} />{" "}
                    {/* Spinner while loading */}
                  </div>
                ) : (
                  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
                    {products.map((product, index) => (
                      <div
                        key={index}
                        className="cursor-pointer"
                        onClick={() =>
                          navigate(`/product/${product._id}`, {
                            state: { product },
                          })
                        }
                      >
                        <ProductCard product={product} />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Description Content */}
        {/* {isDescriptionOpen && ( */}
        <div className="mt-16 mb-5 bg-white border-2 z-50 p-4 rounded-xl hidden md:block">
          <p className="text-[20px] font-bold">Description:</p>
          <p className="text-[16px]">{product.description}</p>
        </div>
        {/* )} */}
        <div className="mt-[10vh] md:block hidden">
          <Reviews reviews={reviews} />
        </div>
        {/* Product Cards Section - "You may also like" */}
        <div className="md:mt-16 mt-[10vh] md:block hidden">
          <div className="flex justify-between items-center">
            <p className="font-bold text-[19px] md:text-[21px] my-2">
              You may also like
            </p>
          </div>
          <div>
            {loading ? (
              <div className="flex justify-center items-center h-[200px]">
                <ClipLoader color="#000" size={50} />{" "}
                {/* Spinner while loading */}
              </div>
            ) : (
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
                {products.map((product, index) => (
                  <div
                    key={index}
                    className="cursor-pointer"
                    onClick={() =>
                      navigate(`/product/${product._id}`, {
                        state: { product },
                      })
                    }
                  >
                    <ProductCard product={product} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <AuthModal isOpen={showModal} onClose={handleCloseModal} />
    </>
  );
};
