import { DEV_APIBaseUrl } from "../../config/config";

export const LoginApi = async (data) => {
    try {
        const response = await fetch(DEV_APIBaseUrl + '/user/login', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        const result = await response.json();
        return result; 

    } catch (error) {
        console.error('Error during login:', error);
        throw error; 
    }
}


export const SignUpApi = async (data) => {
    try {
        const response = await fetch(DEV_APIBaseUrl + '/user/create', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        const result = await response.json();
        return result; 

    } catch (error) {
        console.error('Error during Account Creation:', error);
        throw error; 
    }
}


