import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  totalItems: 0,
  totalBill: 0,
  discountedBill: 0,
  cartId: '',
};

export const cartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    addItem: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.items.find((item) => item._id === newItem._id);

      if (existingItem) {
        if (existingItem.size === newItem.size) {
          existingItem.qty += newItem.qty;
        } else {
          state.items.push({
            ...newItem,
          });
        }
      } else {
        state.items.push({
          ...newItem,
        });
      }

      state.totalItems += newItem.qty;
      state.totalBill += newItem.price * newItem.qty;
      state.discountedBill += newItem.discountedPrice * newItem.qty;
    },

    removeItem: (state, action) => {
      const id = action.payload._id;
      const existingItem = state.items.find((item) => item._id === id);

      if (existingItem) {
        state.totalItems -= existingItem.qty;
        state.totalBill -= existingItem.price;
        state.items = state.items.filter((item) => item._id !== id);
        state.discountedBill -=
          existingItem.discountedPrice * existingItem.qty;
      }
    },

    resetCart: (state) => {
      state.items = [];
      state.totalItems = 0;
      state.totalBill = 0;
      state.discountedBill = 0;
      state.cartId = '';
    },

    updateCartTotals: (state, action) => {
      const { totalItems, totalBill, discountedBill } = action.payload;
      state.totalItems = totalItems;
      state.totalBill = totalBill;
      state.discountedBill = discountedBill;
    },

    updateCartId: (state, action) => {
      const { cartId } = action.payload;
      state.cartId = cartId;
    },

  },
});

export const { addItem, removeItem, resetCart, updateCartTotals, updateCartId } = cartSlice.actions;

export default cartSlice.reducer;
