import React from "react";
import imag from "../../assets/pic.jpg";
import { StarIcon } from "@heroicons/react/24/outline";

export const ProductCard = (props) => {
  return (
    <div className="w-full max-w-[220px] h-auto">
      {/* Image Section */}
      <div className="w-full h-[260px] sm:h-[200px] md:h-[250px] lg:h-[260px]">
        {console.log(props.product.imageLink[0])}
        <img src={props.product.imageLink[0]} alt="" className="h-full w-full object-cover" />
      </div>
      
      {/* Product Info Section */}
      <div className="w-full mt-2">
        {/* Rating */}
        <div className="flex items-center">
          <StarIcon className="h-5 w-5 text-yellow-400 fill-current" />
          <p className="ml-1 text-sm">{props.product.rating}({props.product.reviews})</p>
        </div>
        
        {/* Product Title */}
        <div>
          <p className="font-bold text-sm sm:text-base lg:text-lg">
            {props.product.title}
          </p>
        </div>
        
        {/* Price Section */}
        <div className="flex items-center">
          <p className="text-sm sm:text-base line-through mr-2">£{props.product.price}</p>
          <p className="font-bold text-red-500 text-sm sm:text-base">£{props.product.discountedPrice}</p>
        </div>
      </div>
    </div>
  );
};
