import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaGoogle, FaTiktok, FaSnapchat } from 'react-icons/fa'; // Importing icons from react-icons

export const Footer = () => {
  return (
    <div className='h-[45vh] mt-10'>
      <div className='h-[35vh] bg-gray-900 flex flex-col items-center justify-center'>
        <div className='flex space-x-4 mb-4'>
          {/* Social Media Icons */}
          <div className='bg-white rounded-full p-2'>
            <FaFacebookF className='text-gray-900' />
          </div>
          <div className='bg-white rounded-full p-2'>
            <FaTwitter className='text-gray-900' />
          </div>
          <div className='bg-white rounded-full p-2'>
            <FaInstagram className='text-gray-900' />
          </div>
          <div className='bg-white rounded-full p-2'>
            <FaTiktok className='text-gray-900' />
          </div>
          <div className='bg-white rounded-full p-2'>
            <FaSnapchat className='text-gray-900' />
          </div>
        </div>
        <div className='flex space-x-7 flex-wrap items-center justify-center'>
          {/* Text Links */}
          <a href="#" className='text-white'>Home</a>
          <a href="#" className='text-white'>About Us</a>
          <a href="#" className='text-white'>Payment Methods</a>
          {/* <a href="#" className='text-white'>Terms and Conditions</a>
          <a href="#" className='text-white'>Privacy Policy</a> */}
        </div>
        <div className='my-3 px-8 py-2 border rounded-lg text-black bg-white flex space-x-7 flex-wrap items-center justify-center'>
          {/* Text Links */}
          <p className='text-black font-bold'>World Wide Shipping</p>
        </div>
      </div>
      <div className='h-[10vh] bg-black flex items-center justify-center'>
        <p className='text-white text-[14px]'>© {new Date().getFullYear()} The Unique Sunnah. All rights reserved.</p>
      </div>
    </div>
  );
};
