import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { DEV_APIBaseUrl } from '../../config/config';
import { useSelector } from 'react-redux';

const stripePromise = loadStripe('pk_test_51QA221Rtm9jX1MDeeJkasgnbahYfBD6ADLOPoVpRf3ZsQIjR85gzlDxgcmkZc7plBJ8pVXn0XbueGYsljf6Hd23S00CUMmU3mW');

const Checkout = () => {
  const discountedBill = useSelector((state)=>state.cart.discountedBill)
  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(true);
  const email = useSelector((state)=>state.userData.email)

  useEffect(() => {
    // Fetch clientSecret from backend
    fetch(DEV_APIBaseUrl + '/payment/create-payment-intent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amount: discountedBill*100, currency: 'gbp', receipt_email: email,}),
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret);
        setLoading(false); // Set loading to false when the clientSecret is fetched
      });
  }, []);

  const options = {
    clientSecret: clientSecret,
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      {loading ? (
        <div className="loading-spinner h-[70vh] flex items-center justify-center">
          {/* You can replace this with your preferred loading component */}
          <p>Loading...</p>
        </div>
      ) : (
        clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm />
          </Elements>
        )
      )}
    </div>
  );
};

export default Checkout;
