import React from "react";
import imag from "../../assets/pic.jpg";
import { StarIcon } from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import { removeItem } from "../../store/cartSlice";
import { RemoveItemsFromCartAPI } from "../../api/internal/CartApi";
import { useSelector } from "react-redux";

export const CartItemCard = (props) => {
  console.log(props.item);
  const userId = useSelector((state) => state.user._id);
  const auth = useSelector((state) => state.user.auth);
  let dispatch = useDispatch();
  const RemoveItemBtnClick = () => {
    dispatch(removeItem({ _id: props.item._id }));
    if (auth) {
      const data = {
        userId: userId,
        productId: props.item._id,
        size: props.item.size,
      };
      RemoveItemsFromCartAPI(data);
    }
  };

  return (
    <>
      <div className="w-full max-w-[420px] h-auto flex gap-x-5">
        {/* Image Section */}
        <div className="w-full h-[260px] sm:h-[200px] md:h-[250px] lg:h-[260px]">
          <img
            src={props.item.imageLink[0]}
            alt=""
            className="h-full w-full object-cover"
          />
        </div>

        {/* Product Info Section */}
        <div className="w-full mt-2">
          {/* Rating */}
          <div className="flex items-center">
            <StarIcon className="h-5 w-5 text-yellow-400 fill-current" />
            <p className="ml-1 text-sm">
              {props.item.rating}({props.item.reviews})
            </p>
          </div>

          {/* Product Title */}
          <div>
            <p className="font-bold text-sm sm:text-base lg:text-lg mt-3">
              {props.item.title}
            </p>
          </div>

          {/* Price Section */}
          <div className="flex items-center mt-6">
            <p className="text-sm sm:text-base line-through mr-2">
            £{props.item.price}
            </p>
            <p className="font-bold text-red-500 text-sm sm:text-base">
            £{props.item.discountedPrice}
            </p>
          </div>

          <div className="flex items-center">
            <p className="text-sm sm:text-base mr-2">Size: </p>
            <p className="font-bold text-sm sm:text-base">{props.item.size}</p>
          </div>

          <div className="flex items-center">
            <p className="text-sm sm:text-base mr-2">Quantity: </p>
            <p className="font-bold text-sm sm:text-base">{props.item.qty}</p>
          </div>

          <div className="mt-8">
            <button className="btnRemove" onClick={RemoveItemBtnClick}>
              Remove
            </button>
          </div>
        </div>
      </div>
      <div className="my-10 h-[1px] w-[100%] bg-gray-300"></div>
    </>
  );
};
