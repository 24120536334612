import React, { useEffect, useState } from "react";
import { ProductCard } from "../../components/ProductCard/ProductCard";
import Carousel from "../../components/Caraousal/Caraousal";
import { useNavigate } from "react-router-dom";
import { getProductByCategory } from "../../api/internal/ProductApi";
import ClipLoader from "react-spinners/ClipLoader"; // Import spinner

export const Home = () => {
  const navigate = useNavigate();
  const [men, setMen] = useState(null);
  const [women, setWomen] = useState(null);
  const [girls, setGirls] = useState(null);
  const [boys, setBoys] = useState(null);

  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const getProducts = async () => {
      try {
        const menRes = await getProductByCategory("men", "5");
        const womenRes = await getProductByCategory("women", "5");
        const girlsRes = await getProductByCategory("girls", "5");
        const boysRes = await getProductByCategory("boys", "5");

        setMen(menRes);
        setWomen(womenRes);
        setGirls(girlsRes);
        setBoys(boysRes);
      } catch (error) {
        console.error("Error fetching products", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };
    getProducts();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <ClipLoader size={50} color={"#123abc"} loading={loading} />{" "}
        {/* Spinner */}
      </div>
    );
  }

  return (
    <div className="overflow-hidden">
      <Carousel />

      {/* Abayas Section */}
      <div className="my-10">
        <div className="flex justify-between items-center">
          <p className="font-bold text-[19px] md:text-[21px] my-2">Men</p>
          <div
            className="cursor-pointer hover:underline font-bold"
            onClick={() => navigate("/men")}
          >
            <p>See more</p>
          </div>
        </div>
        <div className="">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {Array.isArray(men) && men.length > 0 ?(
              men?.map((product, index) => (
                <div
                  key={index}
                  className="cursor-pointer"
                  onClick={() =>
                    navigate(`/product/${product._id}`, { state: { product } })
                  }
                >
                  <ProductCard product={product} />
                </div>
              ))
            ) : (
              <p>No products available.</p>
            )}
          </div>
        </div>
        <div className="h-[1px] w-[100%] bg-gray-500 my-5"></div>
      </div>

      {/* Hijabs Section */}
      <div className="my-10">
        <div className="flex justify-between items-center">
          <p className="font-bold text-[19px] md:text-[21px] my-2">Women</p>
          <div
            className="cursor-pointer hover:underline font-bold"
            onClick={() => navigate("/women")}
          >
            <p>See more</p>
          </div>
        </div>
        <div className="">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {Array.isArray(women) && women.length > 0 ? (
              women.map((product, index) => (
                <div
                  key={index}
                  className="cursor-pointer"
                  onClick={() =>
                    navigate(`/product/${product._id}`, { state: { product } })
                  }
                >
                  <ProductCard product={product} />
                </div>
              ))
            ) : (
              <p>No products available.</p>
            )}
          </div>
        </div>
        <div className="h-[1px] w-[100%] bg-gray-500 my-5"></div>
      </div>

      {/* Accessories Section */}
      <div className="my-10">
        <div className="flex justify-between items-center">
          <p className="font-bold text-[19px] md:text-[21px] my-2">Girls</p>
          <div
            className="cursor-pointer hover:underline font-bold"
            onClick={() => navigate("/girls")}
          >
            <p>See more</p>
          </div>
        </div>
        <div className="">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {Array.isArray(girls) && girls.length > 0 ? (
              girls.map((product, index) => (
                <div
                  key={index}
                  className="cursor-pointer"
                  onClick={() => navigate(`/product/${product._id}`, { state: { product } })}
                >
                  <ProductCard product={product} />
                </div>
              ))
            ) : (
              <p>No products available.</p>
            )}
          </div>
        </div>
        <div className="h-[1px] w-[100%] bg-gray-500 my-5"></div>
      </div>

      <div className="my-10">
        <div className="flex justify-between items-center">
          <p className="font-bold text-[19px] md:text-[21px] my-2">Boys</p>
          <div
            className="cursor-pointer hover:underline font-bold"
            onClick={() => navigate("/boys")}
          >
            <p>See more</p>
          </div>
        </div>
        <div className="">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {Array.isArray(boys) && boys.length > 0 ? (
              boys.map((product, index) => (
                <div
                  key={index}
                  className="cursor-pointer"
                  onClick={() => navigate(`/product/${product._id}`, { state: { product } })}
                >
                  <ProductCard product={product} />
                </div>
              ))
            ) : (
              <p>No products available.</p>
            )}
          </div>
        </div>
        <div className="h-[1px] w-[100%] bg-gray-500 my-5"></div>
      </div>
    </div>
  );
};
