import React, { useEffect, useState } from "react";
import { ProductCard } from "../../components/ProductCard/ProductCard";
import { useParams } from "react-router-dom";
import { getProductByCategory, getProductBySubCategory } from "../../api/internal/ProductApi";
import ClipLoader from "react-spinners/ClipLoader"; 
import { useNavigate } from "react-router-dom";

const formatSubcategory = (subcategory) => {
  if (!subcategory) return ""; // Return an empty string if subcategory is undefined
  return subcategory
    .replace(/-/g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const CategoryScreen = () => {
  const { category, subcategory } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      setError(null);
      try {
        if (subcategory) {
          const data = await getProductBySubCategory(category, subcategory, 'all');
          setProducts(data);
        } else {
          const data = await getProductByCategory(category, 'all');
          setProducts(data);
        }
      } catch (err) {
        setError('Failed to fetch products. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    getProducts();
  }, [category, subcategory]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <ClipLoader size={50} color={"#123abc"} loading={loading} />{" "}
        {/* Spinner */}
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center my-10 h-[100vh] flex items-center justify-center text-red-500">
        {error}
      </div>
    );
  }

  return (
    <div className="mt-10">
      <p className="font-bold text-[19px] md:text-[24px] my-6">
        {formatSubcategory(subcategory) || formatSubcategory(category)} {/* Show category name if subcategory is undefined */}
      </p>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-7">
        {products.length > 0 ? (
          products.map((product, index) => (
            <div key={index} className="cursor-pointer"  onClick={() => navigate(`/product/${product._id}`, { state: { product } })}>
            <ProductCard product={product} />
            </div>
          ))
        ) : (
          <div className="h-[75vh]">No products found.</div>
        )}
      </div>
    </div>
  );
};
