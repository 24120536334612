import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetCart } from '../../store/cartSlice';
import { resetUser } from '../../store/userSlice';
import { useDispatch } from 'react-redux';

export const AccountScreen = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const LogoutBtnClick = () => {
    dispatch(resetCart())
    dispatch(resetUser())
    navigate("/")
  }

  return (
    <div className='w-[100%] h-[90vh] flex items-center justify-center'>
      <div className="w-[90%] max-w-[500px] p-8 bg-white shadow-md rounded-lg border">
        <h2 className="text-2xl font-bold mb-6 text-center">Account Information</h2>
        
        <div className="mb-4">
          <p className="font-semibold text-gray-600">First Name:</p>
          <p className="text-lg">{user.firstname || 'N/A'}</p>
        </div>

        <div className="mb-4">
          <p className="font-semibold text-gray-600">Last Name:</p>
          <p className="text-lg">{user.lastname || 'N/A'}</p>
        </div>

        <div className="mb-4">
          <p className="font-semibold text-gray-600">Email:</p>
          <p className="text-lg">{user.email || 'N/A'}</p>
        </div>

        <div className="mb-4">
          <p className="font-semibold text-gray-600">Password:</p>
          <p className="text-lg">••••••••</p> {/* Displays 8 dots */}
        </div>

        <div className="mb-4 flex items-center justify-center">
          <button className='btn' onClick={()=>navigate('/orderhistory')}>Orders History</button>
        </div>

        <div className="mb-4 flex items-center justify-center">
          <button className='btnRemove' onClick={LogoutBtnClick}>Logout</button>
        </div>

      </div>
    </div>
  );
};
