import React, { useState } from "react";
import { Textbox } from "../../components/Textbox/Textbox";
import { GetOrder } from "../../api/internal/OrderApi";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader"; // Import the spinner

export const GetOrderDetailsScreen = () => {
  const navigate = useNavigate();
  const [orderId, setOrderId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const GetBtnClick = async () => {
    if (orderId === "") {
        setError("Kindly Add Order Id")
    } else {
      setLoading(true);
      setError(null);
      try {
        let data = await GetOrder(orderId);
        console.log(data[0]);
        data = data[0];
        setLoading(false); 
        navigate("/orderdetails", { state: { order: data } });
      } catch (e) {
        setLoading(false);
        setError("Failed to fetch order details. Please try again.");
        console.log(e); 
      }
    }
  };

  return (
    <div className="h-[40vh] md:h-[100vh] w-[100%] flex items-center flex-col gap-y-5 mt-32">
      <h1 className="font-bold text-2xl">Get Order Details</h1>
      <Textbox
        type="text"
        value={orderId}
        onChange={(e) => setOrderId(e.target.value)}
        placeholder="Enter Order Id"
      />
      {error && <p className="text-red-500">{error}</p>}{" "}
      {/* Show error if any */}
      {/* Show loading spinner or the button */}
      {loading ? (
        <ClipLoader color="#36d7b7" size={35} />
      ) : (
        <button className="btn" onClick={GetBtnClick}>
          Get
        </button>
      )}
    </div>
  );
};
