import { configureStore } from "@reduxjs/toolkit";
import user from "./userSlice";
import cartReducer from "./cartSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Correct import
import { combineReducers } from "redux";
import userData from "./userDataSlice"

// Set expiration duration (24 hours in milliseconds)
const EXPIRATION_DURATION = 24 * 60 * 60 * 1000; // 24 hours

// Create a wrapper for storage to add expiration logic
const createExpirationStorage = (storage) => {
  return {
    ...storage,
    setItem: (key, value) => {
      const expiringValue = JSON.stringify({
        value,
        timestamp: Date.now(), // Save current time
      });
      return storage.setItem(key, expiringValue);
    },
    getItem: async (key) => {
      const result = await storage.getItem(key);
      if (!result) return null;

      const { value, timestamp } = JSON.parse(result);

      // Check if 24 hours have passed
      if (Date.now() - timestamp > EXPIRATION_DURATION) {
        storage.removeItem(key); // Clear the expired state
        return null; // Return null to reset state
      }

      return value;
    },
  };
};

// Use the custom expiring storage
const expiringStorage = createExpirationStorage(storage);

// Create persist configuration for user and cart slices with expiring storage
const persistConfig = {
  key: 'root', // key for localStorage
  storage: expiringStorage, // use the expiringStorage
  whitelist: ['user', 'cart', 'userData'], // only persist user and cart slices
};

// Combine reducers
const rootReducer = combineReducers({
  user,
  cart: cartReducer,
  userData,
});

// Persist the reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store with persisted reducer
const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store); // Export persistor

export default store;
