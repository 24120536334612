import { DEV_APIBaseUrl } from "../../config/config";

export const PlaceOrder = async (data) => {
    let apiLink = DEV_APIBaseUrl + "/order/create";
    try {
      const response = await fetch(apiLink, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
  
      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error during Getting products", error);
      throw error;
    }
}



export const GetOrders = async (userId) => {
  let apiLink = DEV_APIBaseUrl + "/order/get/" + userId;
  try {
    const response = await fetch(apiLink, {
      method: "GET",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();
    return result.Orders;
  } catch (error) {
    console.error("Error during Getting Orders", error);
    throw error;
  }
}


export const GetOrder = async (orderId) => {
  let apiLink = DEV_APIBaseUrl + "/order/getbyid/" + orderId;
  try {
    const response = await fetch(apiLink, {
      method: "GET",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();
    return result.Orders;
  } catch (error) {
    console.error("Error during Getting Order", error);
    throw error;
  }
}