import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { getAllProductsAPI } from "../../api/internal/ProductApi";
import { ProductCard } from "../../components/ProductCard/ProductCard";
import ClipLoader from "react-spinners/ClipLoader"; // Import spinner

export const SearchScreen = () => {
  const navigate = useNavigate();
  const [productsState, setProductsState] = useState([]); // All products from API
  const [searchText, setSearchText] = useState(""); // Search input
  const [filteredProducts, setFilteredProducts] = useState([]); // Filtered products to display
  const [loading, setLoading] = useState(false); // Loading state

  // Fetch products from API when component mounts
  useEffect(() => {
    GetProductsFromAPI();
  }, []);

  // Fetch products from API
  const GetProductsFromAPI = async () => {
    setLoading(true);
    const products = await getAllProductsAPI();
    setProductsState(products);
    setLoading(false);
  };

  // Update filtered products whenever searchText or productsState changes
  useEffect(() => {
    const filtered = productsState
      .filter((product) =>
        product.title.toLowerCase().includes(searchText.toLowerCase()) // Filter by title
      )
      .slice(0, 20); // Limit to 20 products
    setFilteredProducts(filtered);
  }, [searchText, productsState]); // Dependency on searchText and productsState

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <ClipLoader size={50} color={"#123abc"} loading={loading} />{" "}
        {/* Spinner */}
      </div>
    );
  }

  return (
    <div className="w-[100%] mt-8 flex items-center flex-col">
      <div className="md:w-[70%] w-[90%]">
        <div className="w-[100%] h-[40px] bg-gray-200 flex items-center justify-end rounded px-5">
          <input
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)} // Update searchText on input change
            placeholder="Search here..."
            className="bg-gray-200 w-[95%] outline-none"
          />
          <MagnifyingGlassIcon className="h-7 w-7 mr-3" />
        </div>
      </div>
      <div className="mt-12">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
          {Array.isArray(filteredProducts) && filteredProducts.length > 0 ? (
            filteredProducts.map((product, index) => (
              <div
                key={index}
                className="cursor-pointer"
                onClick={() =>
                  navigate(`/product/${product._id}`, { state: { product } })
                }
              >
                <ProductCard product={product} />
              </div>
            ))
          ) : (
            <div className="w-full h-[50vh]">
            <p className="mx-auto">No products found.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
