import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { DSTextbox } from "../../components/DeliveryScreenTextBox/DSTextbox";
import { useDispatch } from "react-redux";
import { setUserData } from "../../store/userDataSlice";

export const CheckoutScreen = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [country, setCountry] = useState();
  const [city, setCity] = useState();
  const [postalCode, setPostalCode] = useState();
  const [address, setAddress] = useState();
  const [apartment, setApartment] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const dispatch = useDispatch();

  let NumberOfItems = useSelector((state) => state.cart.totalItems);
  let totalBill = useSelector((state) => state.cart.totalBill);
  let discountedPrice = useSelector((state) => state.cart.discountedBill);

  const ProceedToPaymentBtnClick = () => {
    if (
      firstName &&
      lastName &&
      country &&
      city &&
      postalCode &&
      address &&
      apartment &&
      phone &&
      email
    ) {
      const data = {
        firstName,
        lastName,
        country,
        city,
        postalCode,
        address,
        apartmentSuite: apartment,
        phone,
        email,
      };
      dispatch(setUserData(data));
      navigate("/newcheckout");
    }
    else{
      alert("Fill all details")
    }
  };

  return (
    <div className="grid-cols-2 flex mt-12 md:flex-row flex-col">
      <div className="md:w-[65%] w-[100%] md:mx-10 md:px-10 px-2 py-5 border-2 rounded-3xl order-1 md:order-1">
        <p className="font-bold text-[24px] mb-5">Delivery Address</p>
        <div className=" flex flex-col gap-y-1">
          <DSTextbox
            placeholder="Country/Region"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
          <div className="flex">
            <DSTextbox
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <DSTextbox
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <DSTextbox
            placeholder="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <DSTextbox
            placeholder="Apartment, Suite, etc"
            value={apartment}
            onChange={(e) => setApartment(e.target.value)}
          />

          <div className="flex">
            <DSTextbox
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <DSTextbox
              placeholder="Postal Code"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
          </div>
          <DSTextbox
            placeholder="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <DSTextbox
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>
      <div className="md:w-[35%] w-[100%] md:h-[68vh] h-[65vh] md:mx-10 px-10 py-5 border-2 rounded-3xl order-2 md:order-2 mt-10 md:mt-0">
        <div className="">
          <p className="font-bold text-[24px]">Items Summary</p>
          <div className="flex justify-between mt-8">
            <p>No. of Items</p>
            <p>{NumberOfItems}</p>
          </div>
          <div className="flex justify-between mt-2">
            <p>Subtotal</p>
            <p>£{totalBill}</p>
          </div>
          <div className="flex justify-between mt-2">
            <p>Discount</p>
            <p>£{totalBill - discountedPrice}</p>
          </div>
          <div className="h-[1px] w-[100%] bg-gray-500 my-4"></div>
          <div className="flex justify-between mb-10">
            <p>Total</p>
            <p>£{discountedPrice}</p>
          </div>
          <div className="flex flex-col gap-y-3">
            <button className="lightBtn" onClick={ProceedToPaymentBtnClick}>
              Proceed to Payment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
